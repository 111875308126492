import { Route } from "react-router-dom/cjs/react-router-dom.min"
import JobPlaning from "./JobPlaning/JobPlaning"


export const branchApiNm = {
  getJobOrderAllDetail: "/branch/getJobOrderAllDetail"
}

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobOrderDriverIncome: "ลงเบอร์ตู้-ค่าเทียว"
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },]

export const extraRoute = (path)=> [
  <Route key="JobPlaning" path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
]

export const extraMainRoute = (path)=> [
]
