import dayjs from "dayjs"
import { jsPDF } from "jspdf";

import companyLogo from "branch/resource/company-logo.png"
// import { numberFormat } from "utils";
import { branchNoText } from "utils/branchNoText";
//{jobOrderData, shipmentData, jobOrdIds, fName, msData, isPrintDateTime}


export const printJobOrder = ({ printDataArr, msData }) => {

  console.log("printDataArr::", printDataArr)

  const headerText = {
    JobOrdId: "ORDER NO.",
    JobNo: "JOB NO.",
    AptTmDte: "วันที่",
    APTTmStr: "วันที่/เวลา",
    CusNm: "ชื่อผู้ว่าจ้าง(ลูกค้า)",
    DrvNm: "ชื่อพนักงานขับรถ",
    LicNm: "ทะเบียนรถ",
    Tel: "เบอร์โทร",
    TukTyp: "ประเภทรถ",
    Bkg: "เบอร์บุ๊คกิ้ง",
    Agnt: "เอเย่นต์เรือ",
    Qty: "จำนวน",
    Clsg: "Closing on",
    TakePlc: "สถานที่/รับสินค้าทั่วไป/ตู้หนัก/ตู้เปล่า",
    Loc: "สถานที่/ส่งสินค้าทั่วไป/Load/Unload",
    RtnPlc: "สถานที่คืน/ตู้หนัก/ตู้เปล่า",
    TakeDteStStr: "วันที่/เวลา",
    AtpTmDteStr: "วันที่/เวลา",
    RtnDteEnStr: "วันที่/เวลา",
    ContSizeFull: "ขนาดตู้",
    ContNo1: "เบอร์ตู้(1)",
    SealNo1: "เบอร์ซีล",
    ContNo2: "เบอร์ตู้(2)",
    SealNo2: "เบอร์ซีล",
    TakeDte: "วันที่รับตู้",
    RtnDte: "วันที่คืนตู้",
    LicNm: "ทะเบียนรถ",
    POD: "เมืองปลายทาง",
    TempRtn: "อุณหภูมิก่อนคืนตู้",
    RtnTm: "เวลาคืนตู้",
    CmdyTyp: "ชื่อสินค้า",
    MileSt: "เลขไมล์รับตู้",
    MileEn: "เลขไมล์คืนตู้",
    MileGenSt: "เลขชั่วโมงเครื่องปั่นไปเริ่มงาน",
    MileGenEn: "เลขชั่วโมงเครื่องปั่นไปจบงาน",
    OrderText: "คำสั่งลากตู้",
    Expense: "รายละเอียดค่าใช้จ่าย Lift on/off",
    GateFee: "Gate Fee",
    RcptNm: "ใบเสร็จออกนาม",
    RcptAddr: "",
    RcptTaxId: "",
    Rmk: "หมายเหตุ",
  }

  console.log("printDataArr::", printDataArr)
  const companyData = msData.serverData.CompanyData

  const pageSize = [8.3, 11.7]; //A4
  const midPage = pageSize[0] / 2
  const lineEndMid = midPage - 0.2
  const defaultHeaderSize = 20;
  const contentHeaderSize = 13;
  const contentSize = 14;

  const startY = 0.5
  const startX = 0.7
  const endX = 8
  const lineHeight = 0.3
  const lineHeightContent = 0.3

  const col1 = startX
  const colMid = midPage - 0.5
  const colEnd = midPage + 1.5
  const dateCol = midPage + 2

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });
  doc.setFont("THSarabun")


  const drawText = (joData, name, xPos, yPos, width) => {
    doc.text(`${headerText[name]}`, xPos, yPos)
    doc.text(`:`, xPos + width, yPos)
    doc.text(`${joData[name]}`, xPos + width + 0.1, yPos)
  }
  const drawSmallText = (joData, name, xPos, yPos) => {
    drawText(joData, name, xPos, yPos, 0.6)
  }
  const drawMidlText = (joData, name, xPos, yPos) => {
    drawText(joData, name, xPos, yPos, 1)
  }
  const drawLargelText = (joData, name, xPos, yPos) => {
    drawText(joData, name, xPos, yPos, 2)
  }
  // const drawTextWithDot = (text, xPos, yPos, endXPos) => {
  //   doc.text(text, xPos, yPos)
  //   doc.line(xPos + doc.getTextWidth(text), yPos + 0.03, endXPos, yPos + 0.03)
  // }
  // const drawRow = (jobOrderData, name1, name2, yPos) => {
  //   doc.text(`${headerText[name1]}    ${jobOrderData[name1] || ""}`, startX, yPos)
  //   doc.line(startX + doc.getTextWidth(headerText[name1]), yPos + 0.03, lineEndMid, yPos + 0.03)
  //   doc.text(`${headerText[name2]}    ${jobOrderData[name2] || ""}`, midPage, yPos)
  //   doc.line(midPage + doc.getTextWidth(headerText[name2]), yPos + 0.03, endX, yPos + 0.03)
  // }
  let i = 0;

  for (const { jobOrderData, shipmentData, jobOrdIds, fName } of printDataArr) {
    shipmentData.ShpmTypNm = msData.shipmentTypes.find(st => st.ShpmTypId === shipmentData.ShpmTypId)?.Name || ""
    jobOrderData.TakePlc = msData.poiObj[jobOrderData.TakePOIId] || ""
    jobOrderData.Loc = msData.poiObj[jobOrderData.LocPOIId] || ""
    jobOrderData.RtnPlc = msData.poiObj[jobOrderData.RtnPOIId] || ""
    const drvObj = msData.driverObj[jobOrderData.DrvId] || { FName: "", LName: "", Tel: "-" }
    jobOrderData.DrvNm = `${drvObj.FName} ${drvObj.LName}`
    jobOrderData.LicNm = jobOrderData.TukId ? msData.truckObj[jobOrderData.TukId]?.LicNm || "" : ""
    jobOrderData.Tel = drvObj.Tel
    jobOrderData.AptTmDte = dayjs(jobOrderData.AptTm).format("DD/MM/YYYY")
    jobOrderData.AptTmStr = dayjs(jobOrderData.AptTm).format("DD/MM/YYYY    AT    HH:mm")
    jobOrderData.TakeDteStStr = jobOrderData.TakeDteSt ? dayjs(jobOrderData.TakeDte).format("DD/MM/YYYY    AT    HH:mm") : ""
    jobOrderData.AtpTmDteStr = jobOrderData.AptTm ? dayjs(jobOrderData.AptTm).format("DD/MM/YYYY    AT    HH:mm") : ""
    jobOrderData.RtnDteEnStr = jobOrderData.RtnDteEn ? dayjs(jobOrderData.RtnDteEn).format("DD/MM/YYYY    AT    HH:mm") : ""
    jobOrderData.CusNm = shipmentData.CusNm
    jobOrderData.Bkg = shipmentData.Bkg
    jobOrderData.TukTyp = "TRAILER"
    const contNos = jobOrderData.ContNo?.split(",") || []
    const contNo2s = contNos.filter((_, i) => i > 0)
    jobOrderData.ContNo1 = contNos[0] || ""
    jobOrderData.ContNo2 = contNo2s.join(",") || ""
    const sealNos = jobOrderData.SealNo?.split(",") || []
    const sealNo2s = sealNos.filter((_, i) => i > 0)
    jobOrderData.SealNo1 = sealNos[0] || ""
    jobOrderData.SealNo2 = sealNo2s.join(",") || ""
    jobOrderData.CmdyTyp = shipmentData?.Job?.CmdyTyp || ""
    jobOrderData.Bkg2 = ""
    jobOrderData.Qty = ""
    jobOrderData.Vssl = shipmentData.Vssl ? `${shipmentData.Vssl} V.${shipmentData.Voy}` : ""
    jobOrderData.Clsg = jobOrderData.RtnDteEn? dayjs(jobOrderData.RtnDteEn).format("DD/MM/YYYY HH:mm") : ""
    jobOrderData.OrderText = `${jobOrderData.TakePlc} -> ${jobOrderData.Loc} -> ${jobOrderData.RtnPlc}`
    jobOrderData.Expense = ""
    jobOrderData.GateFee = ""
    jobOrderData.RcptTaxId = `ID ${shipmentData.Job.RcptTaxId}`
    // const sealNos = jobOrderData.ContNo?.split(",") || []
    // const contNo2s = contNos.filter((_, i) => i > 0)
    // jobOrderData.SealNo1 = jobOrderData.SealNo
    // jobOrderData.TakeDteStDte = jobOrderData.TakeDteSt?  dayjs(jobOrderData.TakeDte).format("DD/MM/YYYY") :""
    // jobOrderData.TakeDteStDte = jobOrderData.TakeDteStDte?  dayjs(jobOrderData.TakeDteStDte).format("HH:mm") :""
    // jobOrderData.RtnDteEn = jobOrderData.RtnDteEn?  dayjs(jobOrderData.RtnDteEn).format("DD/MM/YYYY") :""
    let j = 0;
    do {
      if (i + j !== 0) {
        doc.addPage(pageSize, "portrait")
      }
      let yPos = startY
      let startCopy = startY
      yPos = startCopy

      const logoWidth = 1.5
      const logoHeight = 1.5
      const img = new Image()
      img.src = companyLogo

      try {
        doc.addImage(img, 'png', 0.3, yPos - 0.5, logoWidth, logoHeight)
        console.log("imgWidth::", img.width)
      } catch (e) {
        console.log("error::", e)
      }

      doc.setFont("THSarabun", "normal")
      doc.setFontSize(contentSize - 1)
      doc.text("QSHE-FM-JTS(TS)-XXX", 7.3, yPos - 0.2, { align: "center" })
      yPos += lineHeight - 0.08
      doc.text("11 กันยายน 2567 Rev.1", 7.3, yPos - 0.2, { align: "center" })

      doc.setFont("THSarabun", "bold")
      doc.setFontSize(defaultHeaderSize)
      doc.text(companyData.NameTH, midPage, yPos, { align: "center" })
      yPos += lineHeight 
      doc.text(companyData.NameEN, midPage, yPos, { align: "center" })
      yPos += lineHeight / 2
      doc.setLineWidth(0.01)
      doc.line(startX-0.3, yPos, endX, yPos)
      yPos += lineHeight * 3 / 2

      doc.text("ใบปฏิบัติงาน", midPage, yPos, { align: "center" })
      yPos += lineHeight 
      doc.setFont("THSarabun", "normal")
      doc.text("JOB ORDER", midPage, yPos, { align: "center" })
      yPos += lineHeight

      doc.setFont("THSarabun", "normal")
      doc.setFontSize(contentSize)
      drawMidlText(jobOrderData, "JobOrdId", col1, yPos)
      drawMidlText(jobOrderData, "AptTmDte", dateCol, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "JobNo", col1, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "CusNm", col1, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "DrvNm", col1, yPos)
      drawSmallText(jobOrderData, "LicNm", colMid, yPos)
      drawSmallText(jobOrderData, "Tel", dateCol, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "TukTyp", col1, yPos)
      drawSmallText(jobOrderData, "ContSizeFull", colMid, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "ContNo1", col1, yPos)
      drawSmallText(jobOrderData, "SealNo1", colMid, yPos)
      drawSmallText(jobOrderData, "Bkg", colEnd, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "ContNo2", col1, yPos)
      drawSmallText(jobOrderData, "SealNo2", colMid, yPos)
      drawSmallText(jobOrderData, "Bkg2", colEnd, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "CmdyTyp", col1, yPos)
      drawSmallText(jobOrderData, "Qty", colEnd, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "Vssl", col1, yPos)
      drawSmallText(shipmentData, "Port", colEnd, yPos)
      yPos += lineHeight
      drawMidlText(shipmentData, "Agnt", col1, yPos)
      drawSmallText(jobOrderData, "Clsg", colEnd, yPos)
      yPos += lineHeight
      drawLargelText(jobOrderData, "TakePlc", col1, yPos)
      drawSmallText(jobOrderData, "TakeDteStStr", colEnd, yPos)
      yPos += lineHeight
      drawLargelText(jobOrderData, "Loc", col1, yPos)
      drawSmallText(jobOrderData, "AtpTmDteStr", colEnd, yPos)
      yPos += lineHeight
      drawLargelText(jobOrderData, "RtnPlc", col1, yPos)
      drawSmallText(jobOrderData, "RtnDteEnStr", colEnd, yPos)
      yPos += lineHeight + 0.07
      drawSmallText(jobOrderData, "OrderText", col1, yPos)
      yPos += lineHeight + 0.07
      drawMidlText(shipmentData.Job, "RcptNm", col1, yPos)
      yPos += lineHeight
      drawMidlText(shipmentData.Job, "RcptAddr", col1, yPos)
      yPos += lineHeight
      drawMidlText(jobOrderData, "RcptTaxId", col1, yPos)
      yPos += lineHeight + 0.07
      drawSmallText(jobOrderData, "Rmk", col1, yPos)
      yPos += lineHeight
      yPos += lineHeight
      yPos += lineHeight


      doc.line(col1 , yPos, col1 +2, yPos )
      doc.line(colMid-0.3 , yPos, colMid -0.3 +2, yPos )
      doc.line(dateCol-0.3 , yPos, dateCol-0.3 +2, yPos )
      yPos += lineHeight
      doc.text("ลงชื่อผู้ว่าจ้างหรือตัวแทนผุ้รับสินค้า", col1+1, yPos, {align: "center"})
      doc.text("ลงชื่อพนักงานขับรถ", colMid -0.3 +1, yPos, {align: "center"})
      doc.text("ลงชื่อ ผู้สั่งงาน", dateCol -0.3+1, yPos, {align: "center"})
      doc.text(fName || "-", dateCol -0.3+1, yPos-0.35, {align: "center"})

      yPos += lineHeight
      yPos += lineHeight

      
      doc.line(startX-0.3, yPos, endX, yPos)
      yPos += lineHeight
      doc.setFontSize(contentSize - 1)
      doc.text(`สำนักงานใหญ่: ${msData.serverData.CompanyData.AddrTH} 10240`, midPage, yPos, {align: "center"})
      yPos += lineHeight-0.03
      doc.text(`โทรศัพท์: +66(2) 710-4030 โทรสาร: +66(2) 710-4036`, midPage, yPos, {align: "center"})
      yPos += lineHeight-0.03
      doc.text(`แหลมฉบัง: คลังสินค้าอันตราย ต.ทุงสุขลา อ.ศรีราชา จ.ชลบุรี 20230`, midPage, yPos, {align: "center"})
      yPos += lineHeight-0.03
      doc.text(`โทรศัพท์: +66(038) 404-710 โทรสาร: +66(038) 404-786`, midPage, yPos, {align: "center"})


      // doc.text(`เลขที่ ${jobOrdIds[j]?.toString() || ""}`, 6.5, yPos - lineHeight/2)
      // yPos += lineHeight 

      // const dateX = 6
      // doc.text(`วันที่    ${jobOrderData.AptTm?dayjs(jobOrderData.AptTm).format("DD/MM/YYYY HH:mm"):""}`, 6, yPos)
      // doc.setLineDashPattern([0.02,0.02],0)
      // doc.line(dateX + doc.getTextWidth("วันที่"), yPos +0.05, endX, yPos+0.05)
      // yPos += lineHeightContent

      // drawRow(jobOrderData, "Bkg", "Agnt", yPos)
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "Temp", "ContSizeFull", yPos)
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "ContNo", "SealNo", yPos)
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "TakeDte", "TakePlc", yPos)
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "RtnDte", "RtnPlc", yPos)
      // yPos += lineHeightContent
      // const dateXPos = midPage - 1
      // const timeXPos = midPage + 1.5
      // drawTextWithDot("อุณหภูมิจากโรงงาน", startX, yPos, dateXPos -0.2 )
      // drawTextWithDot("วันที่", dateXPos, yPos, timeXPos -0.2 )
      // drawTextWithDot("เวลา", timeXPos, yPos, endX  )
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "LicNm", "RtnTm", yPos)
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "Loc", "CmdyTyp", yPos)
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "MileSt", "MileEn", yPos)
      // yPos += lineHeightContent
      // drawRow(jobOrderData, "MileGenSt", "MileGenEn", yPos)
      // yPos += lineHeightContent
      // doc.text("เวลาปั่นไฟ", startX, yPos)
      // const startTmXPos = startX + 1.5
      // drawTextWithDot("เริ่มเวลา", startTmXPos, yPos, midPage - 0.2)
      // drawTextWithDot("วันที่", midPage, yPos, endX)
      // yPos += lineHeightContent
      // drawTextWithDot("จบเวลา", startTmXPos, yPos, midPage - 0.2)
      // drawTextWithDot("วันที่", midPage, yPos, endX)
      // yPos += lineHeightContent *2

      // const colWidth = 2
      // doc.line(startX, yPos, startX + colWidth, yPos)
      // doc.line(midPage - colWidth/2, yPos, midPage + colWidth/2, yPos)
      // doc.line(endX - colWidth, yPos, endX, yPos)
      // yPos += lineHeight
      // const drvNm = jobOrderData.DrvId ? ` (${msData.driverObj[jobOrderData.DrvId].FName})` : ""
      // doc.text("ผู้ว่าจ้าง / ตัวแทนผู้ว่างจ้าง", startX + colWidth/2, yPos, {align: "center"})
      // doc.text(`พนักงานขับรถ${drvNm}`, midPage, yPos, {align: "center"})
      // doc.text("ผู้สั่งงาน", endX - colWidth/2, yPos, {align: "center"})
      // doc.text(fName, endX - colWidth/2, yPos - lineHeight -0.05, {align: "center"})
      // yPos += lineHeightContent
      // drawTextWithDot("วันที่", startX, yPos, startX + colWidth)
      // drawTextWithDot("วันที่", midPage - colWidth/2, yPos, midPage + colWidth/2)
      // drawTextWithDot("วันที่", endX - colWidth, yPos, endX)
      // yPos += lineHeightContent

      // yPos += lineHeightContent

      // doc.setLineDashPattern([],0)
      // const rcptBoxWidth = pageSize[0] -0.6
      // doc.lines([[rcptBoxWidth , 0], [0, lineHeightContent * 4], [rcptBoxWidth * -1, 0], [0, lineHeightContent * 3 * -1]], startX, yPos - 0.2, [1, 1], 'S', true)
      // const colHeader1 = startX + 0.2
      // const colText1 = colHeader1 + 1
      // doc.setFont("THSarabun", "bold")
      // doc.text("ข้อมูลใบเสร็จ", colHeader1, yPos)
      // yPos += lineHeight
      // doc.setFont("THSarabun", "bold")
      // doc.setFontSize(contentHeaderSize)
      // doc.text("TAX ID : ", colHeader1, yPos)
      // doc.setFont("THSarabun", "normal")
      // doc.setFontSize(contentSize)
      // doc.text(`${shipmentData.Job.RcptTaxId} ${branchNoText(shipmentData.Job.RcptNm, shipmentData.Job.RcptBrchNo)}`, colText1, yPos)
      // yPos += lineHeight
      // doc.setFont("THSarabun", "bold")
      // doc.setFontSize(contentHeaderSize)
      // doc.text("ชื่อใบเสร็จ : ", colHeader1, yPos)
      // doc.setFont("THSarabun", "normal")
      // doc.setFontSize(contentSize)
      // doc.text(shipmentData.Job.RcptNm, colText1, yPos)
      // yPos += lineHeight
      // doc.setFont("THSarabun", "bold")
      // doc.setFontSize(contentHeaderSize)
      // doc.text("ที่อยู่: ", colHeader1, yPos)
      // doc.setFont("THSarabun", "normal")
      // doc.setFontSize(contentSize - 2)
      // const addrs = doc.splitTextToSize(shipmentData.Job.RcptAddr, 6.8)
      // doc.text(addrs.join("\n"), colText1 - 0.3, yPos)

      // yPos += lineHeight * 2
      // doc.setFont("THSarabun", "bold")
      // doc.setFontSize(contentSize -2)
      // doc.text("1. สินค้าทุเรียนใช้ตุ้อายุไม่เกิน 5 ปี", startX, yPos)
      j++
      i++
    } while (j < jobOrdIds.length)
  }




  doc.output('dataurlnewwindow');
  // window.open(doc.autoPrint() , '_blank')
  // doc.autoPrint({variant: 'non-conform'});
  // window.open(doc.output('bloburl'), '_blank')
}